import { api } from '@/helpers';

import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},

  actions: {
    fetchTele2Subscription(s, data) {
      const form = new FormData();

      form.append('phone', data.phone);

      return api.$postMethod(s, {
        url: 'partners/tele2/subscription',
        data: form,
      });
    },

    fetchTele2SubscriptionConfirm(s, data) {
      const form = new FormData();

      form.append('id', data.id);
      form.append('code', data.code);

      return api.$postMethod(s, {
        url: 'partners/tele2/subscription/confirm',
        data: form,
      });
    },
  },
})
