import _ from 'lodash';
import axios from 'axios';
import cookies from "vue-cookies";

// import storeRoot from '../store/store';

const apiUrl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_URL_DEV : process.env.VUE_APP_BASE_URL;

export const api = {
  $getMethod,
  $authMethod,
  $deleteMethod,
  $freshMethod,
  $postMethod,
  $putMethod,
  $patchMethod,
};

function _checkError(error) {
  if (error) {
    return error.response;
  }
}

function $getMethod(store, {url, mutationType, params}) {
  return axios
    .get(apiUrl + url, {
      headers: {
        auth_token: cookies.get('at'),
        'X-Requested-With': 'XMLHttpRequest',
        'X-localization': store.state.curLanguage,
      },

      params,
    })
    .then(response => {
      if (mutationType) {
        store.commit(mutationType, response.data);
      }

      return response.data;
    })
    .catch(_checkError);
}

function $freshMethod(url) {
  return axios
    .get(apiUrl + url)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error) {
        return error.response;
      }
    });
}

function $authMethod(store, {url, data, mutationType}) {
  return axios
    .post(apiUrl + url, data)
    .then(response => {
      if (mutationType) {
        store.commit(mutationType, response.data);
      }

      return response;
    })
    .catch(_checkError);
}

function $postMethod(store, {url, data, mutationType}) {
  let headers = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-localization': store.state.curLanguage,
    auth_token: cookies.get('at'),
  };

  if (data.toString().indexOf('FormData') !== -1) {
    _.assign(headers, {'Content-Type': 'multipart/form-data'})
  } else {
    _.assign(headers, {'Content-Type': 'application/json'})
  }

  return axios({
    method: 'post',
    url: apiUrl + url,
    data,
    headers: headers,
  })
    .then(response => {
      if (mutationType) {
        store.commit(mutationType, response.data);
      }

      return response.data;
    })
    .catch(_checkError);
}

function $putMethod(store, {url, params, data, mutationType}) {
  const _params = params || '';
  const _data = data || '';
  return axios
    .put(apiUrl + url, _data, {
      headers: {
        auth_token: cookies.get('at'),
        'Content-Type': 'application/json',
      },
      params: _params,
    })
    .then(response => {
      if (mutationType) {
        store.commit(mutationType, response.data);
      }

      return response;
    })
    .catch(_checkError);
}

function $deleteMethod(store, {url, mutationType, data}) {
  return axios
    .delete(apiUrl + url, {
      headers: {
        auth_token: cookies.get('at'),
      },

      data,
    })
    .then(response => {
      if (mutationType) {
        store.commit(mutationType, response.data);
      }

      return response;
    })
    .catch(_checkError);
}

function $patchMethod(store, {url, params, data}) {
  const _params = params || '';
  const _data = data || '';
  return axios
    .patch(apiUrl + url, _data, {
      headers: {
        auth_token: cookies.get('at'),
      },
      _params,
    })
    .then(response => {
      return response;
    })
    .catch(_checkError);
}
