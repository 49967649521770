<template>
  <div class="for-life">
    <div class="container">
      <header class="header page__header">
        <div class="header__container">
          <div class="header__logo">
            <a href="https://msk.tele2.ru" target="_blank">
              <img class="header__logo__t2" src="@/assets/img/tele2.png" alt="TELE2" />
            </a>
          </div>
          <div class="header__logo">
            <a href="#" >
              <img class="header__logo__cosmo" src="@/assets/img/loading-bg/cosmo-logo.png" alt="cosmo"
              />
            </a>
          </div>
        </div>
      </header>
    </div>
    <div class="page__content">
      <div class="container">
        <section class="offer page__offer" id="offer">
          <div class="offer__container">
            <div class="offer__container__text"> <h1 class="offer__title">Защита от мошенников</h1>
              <p class="offer__subtitle">
                Поможем оспорить платежи за несостоявшееся путешествие или мероприятие
              </p>
              <div class="button-container-desktop">
                <a class="button offer__button" href="#order" role="button" >Подключить</a>
                <p>Первые три дня - 0 руб., далее - 8 руб./сут.</p>
              </div>
            </div>
            <div class="offer__container__img">
              <img src="@/assets/img/man.png"/>
            </div>

          </div>
          <img class="bg-container" src="@/assets/img/rectangle-t.png"/>
          <div class="button-container">
            <a class="button offer__button" href="#order" role="button" >Подключить</a>
            <p>Первые три дня - 0 руб., далее - 8 руб./сут.</p>
          </div>
        </section>
      </div>
    </div>

    <section class="info page__info" id="info">
      <div class="info__container">
        <div class="container">
          <h2 class="info__title">Спасем абонентов Tele2 от финансовых потерь за три шага:</h2>
          <div class="info__box ">
            <div class="info__row help min">
              <div class="info__col">

                <span>1</span>
                <p>
                  Вы заполните заявку с указанием <br>
                  номера билета, бронирования и даты с суммой платежа
                </p>
              </div>
              <div class="info__col">
                <span>2</span>
                <p>Сервис мгновенно сформирует пакет документов и <br> отправит их в банк</p>
              </div>
              <div class="info__col">
                <span>3</span>
                <p>Вам останется только дождаться поступления<br> денег на ваш счет</p>
              </div>


            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="container">
      <section class="order page__order" id="order">
        <div class="order__container">
          <div class="order__box">
            <h2 class="order__title">Подключите услугу<br> за 8 рублей в день!</h2>

            <div class="order__right">
              <form
                class="form js-validate order__form"
                action="javascript:void(0)"
                id="form-order"
              >
                <div class="field field--type-tel field--dinamic form__field">

                  <div class="field__field">
                    <the-mask
                      v-if="!isSendFormSubscription && isSuccessSubscript !== true"
                      placeholder="Телефон"
                      mask="+7 (###) ###-##-##"
                      type="tel"
                      masked
                      v-model="phone"
                      @input="onfocusPhone"
                    ></the-mask>

                    <the-mask
                      v-if="isSendFormSubscription && isSuccessSubscript !== true"
                      placeholder="Проверочный код"
                      mask="#####"
                      type="numeric"
                      masked
                      v-model="code"
                    ></the-mask>
                  </div>

                  <a
                    v-if="isSendFormSubscription && timer === 0 && isSuccessSubscript !== true"
                    href="javascript:void(0)"
                    @click="sendFormSubscription"
                    class="more"
                  >Отправить код снова?</a>

                  <p v-if="isSuccessSubscript" class="field__field--text">Услуга «Защита от мошенников» успешно подключена!</p>
                </div>

                <button
                  v-if="isSuccessSubscript !== true"
                  class="button form__submit"
                  type="submit"
                  :disabled="phone.length !== 18"
                  @click="isSendFormSubscription ? sendFormSubscriptionCode() : sendFormSubscription()"
                >{{ isSendFormSubscription ? 'Отправить': 'Подключить' }}</button>

                <p>Или наберите *951*1# со своего телефона. После оформления вам будет выслано SMS с инструкцией по использованию услуги.</p>
                <div class="container-submit">
                  <div class=" field field--type-checkbox form__checkbox ">
                    <input class="check"
                      type="checkbox"
                      name="privacy"
                      id="form-order-privacy"
                      required="required"
                      checked="checked"
                      data-validator-required-message="Обязательное поле"
                    />

                    <label class="field__label" for="form-order-privacy">
                      <span class="field__check"></span>Нажимая на кнопку «Подключить», вы соглашаетесь на обработку
                      <a href="#" class="more" @click.prevent="isShown = !isShown">персональных данных</a>, принимаете
                      <a class="more"
                        href="/file/Оферта_Правила_абоненткого_обслуживания_Для_жизни_Теле2_21_10_21.pdf"
                        target="_blank"
                      > оферту</a>
                      <a class="more"
                        href="/file/Оферта_Правила_абоненткого_обслуживания_Для_жизни_Теле2_21_10_21.pdf"
                        target="_blank"
                      > и правила абонентского юридического обслуживания</a>
                    </label>

                    <div class="form-text more__hidden" id="more" v-show="isShown">
                      Нажимая кнопку «Подключить», даю свое согласие ООО «Космовизаком», адрес местонахождения: 420094,
                      г.Казань, ул.Ярослава Гашека, д.1, помещение 4А (далее - Компания), на обработку своих
                      персональных данных, представленных мною в процессе заключения или исполнения абонентского
                      договора, заключаемого с Компанией, в том числе посредством следующих действий: сбор,
                      систематизация, накопление, хранение, в том числе в электронном виде, уточнение
                      (обновление, изменение), перевозка, использование, распространение (включая передачу),
                      обезличивание, блокирование, уничтожение (далее – обработка), в целях заключения, исполнения и
                      прекращения абонентского договора, заключаемого с Компанией, осуществления взаиморасчетов между
                      Агентом и Компанией, защиты конфиденциальной информации (в том числе персональных данных),
                      осуществления хранения конфиденциальной информации, в том числе в электронном виде, а также в
                      целях продвижения на рынке услуг Компании/Агента путем осуществления со мною прямых контактов с
                      помощью средств связи. Обработка персональных данных осуществляется Агентом, а равно Компанией
                      на бумажных и электронных носителях с использованием и без использования средств автоматизации.
                      Настоящее согласие действует в течение 5 (пяти) лет с момента предоставления и прекращается
                      по моему письменному заявлению (отзыву), согласие может быть отозвано при условии письменного
                      уведомления Агента и Компании не менее чем за 30 дней до предполагаемой даты прекращения
                      использования данных Агентом и Компанией".
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>

    <section class="info page__info" >
      <div class="info__container">
        <div class="container">
          <h2 class="info__title">Деньги можно вернуть за:</h2>

          <div class="info__box">
            <div class="info__row">
              <div class="info__col">
                <img src="@/assets/img/roaming.svg" alt="" />

                <div class="info__col__example lh-0">
                  <span>Путешествия и мероприятия</span>
                  <p>Например: отмена или задержка рейса, отмена <br>или перенос мероприятия, отмена онлайн-<br>бронирования отеля</p>
                </div>
              </div>

              <div class="info__col">
                <img src="@/assets/img/cart.svg" alt="" />

                <div class="info__col__example">
                  <span>Товары и услуги</span>
                  <p>Например: некачественные или не доставленные <br>товары, некачественные и не оказанные услуги</p></div>
              </div>

              <div class="info__col mb-2">

                <img src="@/assets/img/wallet2.svg" alt="" />

                <div class="info__col__example">
                  <span>Финансовые вложения</span>
                  <p>Например: Forex брокеры, казино, букмекеры,<br> криптовалюты, финансовые пирамиды, хищение с <br>карт</p></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="container">
      <section class="how">
        <div class="how__container">

          <h2 class="how__title">Как воспользоваться услугой?</h2>
          <p class="phone"><span></span>По номеру <a href="tel:8(800)600-39-62">8 (800) 600-39-62</a></p>

          <p class="personal-area">
            <span></span>
            <a class="personal-area-button" :href="getLkTele2Url()">Через личный кабинет</a>
          </p>
        </div>
      </section>
    </div>

    <div class="container">
      <section class="questions">
        <h1 class="questions__title">Ответы на вопросы</h1>
        <vsa-list>
          <!-- Here you can use v-for to loop through items  -->
          <vsa-item>
            <vsa-heading>
              <h3 class="accordion_title">Как вы поможете вернуть мои деньги? Это законно?</h3>
            </vsa-heading>
            <vsa-content>
              <div class="accordion_item" >
                <p class="accordion_text"> Профессиональные юристы составят за вас заявление в банк на опротестование
                  транзакции на русском и английском языках, помогут собрать необходимые документы и отправят
                  документы в банк. Также проконсультируют, что делать дальше в случае отказа банка, и подготовят
                  заявления в правоохранительные органы.
                  Это законно. Чарджбэк (англ. Chargeback, от charge – требование, back – возврат) — это процедура
                  опротестования банковской транзакции, предусмотренная и регламентируемая международными платежными
                  системами VISA, МИР и MasterCard.
                </p>
              </div>
            </vsa-content>
          </vsa-item>

          <vsa-item>
            <vsa-heading>
              <h3 class="accordion_title">Какой список документов необходим?</h3>

            </vsa-heading>

            <vsa-content>
              <div class="accordion_item" >

                <p class="accordion_text"> По каждому случаю алгоритм в Личном кабинете запрашивает
                  определённые данные. Например, по некачественному товару потребуется фото товара, а если он
                  не получен, потребуется написать конечную дату получения, а также предоставить скриншоты переписки
                  с продавцом. Никаких отсканированных документов (например, паспорта) не потребуется!</p>
              </div>
            </vsa-content>
          </vsa-item>

          <vsa-item>
            <vsa-heading>
              <h3 class="accordion_title">Нужно ли что-то доплачивать за помощь юриста?</h3>
            </vsa-heading>

            <vsa-content>
              <div class="accordion_item" >
                <p class="accordion_text"> Нет. Услуга предоставляется по подписке и не требует никаких дополнительных
                  платежей. Мы не берем комиссии с возвращенных денежных средств. </p>


              </div>
            </vsa-content>
          </vsa-item>

          <vsa-item>
            <vsa-heading>
              <h3 class="accordion_title">В каких случаях возможен возврат?</h3>
            </vsa-heading>

            <vsa-content>
              <div class="accordion_item" >
                <p class="accordion_text">Чарджбэк можно инициировать по следующим причинам: </p>
                <ul class="accordion_text">
                  <li>
                    Туристические:
                    <ul>
                      <li>Отмена туристической путевки;</li>
                      <li>Изменение условий туристической путевки;</li>
                      <li>Отмена или изменение авиарейса;</li>
                      <li>Непредоставление места на авиарейс (в т.ч. овербукинг);</li>
                      <li>Непредоставление забронированного места в отеле;</li>
                      <li>Отмена бронирования в отеле;</li>
                      <li>Изменения условий бронирования в отеле.</li>
                    </ul>
                  </li>
                  <li>
                    Финансовые:
                    <ul>
                      <li>Блокировка вывода средств с онлайн казино;</li>
                      <li>Обнуление счета брокера;</li>
                      <li>Блокировка доступа к личному кабинету букмекера или криптоплатформы.</li>
                    </ul>
                  </li>
                  <li>Торговой точкой не осуществлен обещанный возврат (refund);</li>
                  <li>Двойное/случайное списание средств;</li>
                  <li>Произошло списание суммы, отличную от стоимости покупки;</li>
                  <li>Списание со счета без авторизации или с карточки списаны деньги за покупку, которую держатель карты не совершал;</li>
                  <li>Товар или услуга не соответствует описанию;</li>
                  <li>Покупатель не удовлетворен продуктом или сервисом, например на деле товар отличается от того, что было
                    обещано ранее продавцом, или услуги были оказаны не в полном объеме, или продавец намеренно ввел в заблуждение покупателя;</li>
                  <li>Оплаченный товар не был доставлен или услуга не была оказана;</li>
                  <li>Клиент получил товар или услугу, но оплату произвел другим способом (наличными, с другой карты и т. п.);</li>
                  <li>Денежные средства не были выданы банкоматом или выданы не в полном объеме;</li>
                  <li>Продавец отказался принимать товар обратно или намеренно затягивает сроки приемки.</li>
                </ul>
              </div>
            </vsa-content>
          </vsa-item>

          <vsa-item>
            <vsa-heading>
              <h3 class="accordion_title">А если не получится вернуть деньги?</h3>
            </vsa-heading>

            <vsa-content>
              <div class="accordion_item" >
                <p class="accordion_text">Гарантировать возврат на 100% невозможно, так как решение принимается
                  регулирующими процедуру финансовыми институтами (платежные системы, банки). Профессиональные юристы
                  в рамках подписки «Защита от мошенников» проконсультируют, что делать дальше в случае отказа банка или
                  платежной системы, а также подготовят заявления в правоохранительные органы в случае необходимости.</p>
              </div>
            </vsa-content>
          </vsa-item>

          <vsa-item>
            <vsa-heading>
              <h3 class="accordion_title">В какие сроки можно опротестовать транзакцию?</h3>
            </vsa-heading>

            <vsa-content>
              <div class="accordion_item" >
                <p class="accordion_text">Официальные сроки опротестования транзакций, установленные платежными
                  системами, могут доходить до 540 дней. Однако шансы, что банк примет ваше обращение на рассмотрение,
                  будут выше, если соблюсти следующие сроки: Visa – 180 дней, Mastercard – 120 дней. Чем позднее срок,
                  тем меньше шансов вернуть деньги.</p>
              </div>
            </vsa-content>
          </vsa-item>

          <vsa-item>
            <vsa-heading>
              <h3 class="accordion_title">Как отключить подписку?</h3>
            </vsa-heading>

            <vsa-content>
              <div class="accordion_item" >
                <p class="accordion_text">Отправьте смс со словом “стоп” на номер 3912.</p>
              </div>
            </vsa-content>
          </vsa-item>

          <vsa-item>
            <vsa-heading>
              <h3 class="accordion_title">Возможен ли чарджбэк при оплате через электронные деньги ?</h3>
            </vsa-heading>

            <vsa-content>
              <div class="accordion_item" >
                <p class="accordion_text">
                  <span>Нет.</span> Чарджбэк – это банковская процедура, поэтому инициировать возврат денег при оплате через электронные
                  деньги (Kiwi, Webmoney, ЮMoney) невозможно. Если вы произвели оплату через электронные деньги и
                  имеете претензии к товару или услуге, то лучше обратиться к местным юристам, МВД, органам защиты прав
                  потребителей и т.д.
                </p>
              </div>
            </vsa-content>
          </vsa-item>
        </vsa-list>
      </section>
    </div>

    <footer class="footer">
      <div class="container">
        <div class="promo__subtitle">Услуга «Защита от мошенников» (бесплатно первые 3 дня, далее 8 руб./сутки, включая <br>налоги)
          предоставляется ООО «Космовизаком» на условиях
          <a href="/file/Оферта_Правила_абоненткого_обслуживания_Защита от мошенников_Теле2.pdf" target="_blank">оферты</a>.<br>
          Оплата осуществляется переводом с лицевого счета абонента Tele2 на условиях <br>
          <a href="/file/Oferta-Tele2-01.04.2021.pdf" target="_blank">оферты</a>
          и условиях <a href="/file/Oferta-platezhnogo-servisa-Tele2-RNKO.pdf" target="_blank">оферты</a> РНКО Единая касса.
        </div>
        <!--          <ul class="support__list">-->
        <!--            <li><a href="/file/Оферта_Правила_абоненткого_обслуживания_Для_жизни_Теле2_21_10_21.pdf">Оферта ООО “Космовизаком”</a></li>-->
        <!--            <li><a href="/file/Oferta-Tele2-01.04.2021.pdf">Оферта Tele2</a></li>-->
        <!--            <li><a href="/file/Oferta-platezhnogo-servisa-Tele2-RNKO.pdf">Оферта ООО РНКО Единая касса</a></li>-->
        <!--          </ul>-->
        <div class="copyright">
          © {{ new Date().getFullYear() }} ООО «Космовизаком»
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';

  import { VsaList, VsaItem, VsaHeading, VsaContent } from 'vue-simple-accordion';

  export default {
    name: 'ForLife',

    components: {
      VsaList,
      VsaItem,
      VsaHeading,
      VsaContent,
    },

    metaInfo: {
      title: 'Услуга "Защита от мошенников" | Чарджбэк-юрист',

      meta: [
        { name: 'description', content: 'Поможем оспорить ошибочные или мошеннические операции по карте.' },
      ],
    },

    data() {
      return {
        isShown: false,
        phone: '',
        code: null,
        subscriptId: null,
        timer: 60,
        isSendFormSubscription: false,
        isSuccessSubscript: null,
      };
    },

    methods: {
      ...mapActions({
        fetchTele2Subscription: 'fetchTele2Subscription',
        fetchTele2SubscriptionConfirm: 'fetchTele2SubscriptionConfirm',
      }),

      metrikaGoal(target) {
        if (window && window.ym) {
          window.ym(88423715, 'reachGoal', target);

          return true;
        }

        return false;
      },

      getLkTele2Url() {
        return process.env.NODE_ENV === 'development'
          ? 'https://tele2-lk-test.чарджбэк-юрист.рф/'
          : 'https://tele2-lk.чарджбэк-юрист.рф/';
      },

      onfocusPhone() {
        if (this.phone === '+7 (8') {
          this.phone = '+7'
        }
      },

      sendFormSubscriptionCode() {

        this.fetchTele2SubscriptionConfirm({
          code: this.code,
          id: this.subscriptId,
        }).then(res => {
          if (res.status && res.data.id) {
            this.subscriptId = res.data.id;

            this.isSuccessSubscript = true;

            this.metrikaGoal('go_to_lk');
            window.location.href = process.env.NODE_ENV === 'development'
              ? 'https://tele2-lk-test.чарджбэк-юрист.рф/'
              : 'https://tele2-lk.чарджбэк-юрист.рф/'
          } else {
            this.isSuccessSubscript = false;
          }
        })
      },

      sendFormSubscription() {
        this.isSendFormSubscription = true;
        this.isSuccessSubscript = null;

        if (this.timer !== 0) {
          let timer = setInterval(function() {
            --this.timer;

            if(this.timer === 0) {
              clearInterval(timer)
            }
          }.bind(this), 1000)
        } else {
          this.timer = -1
        }

        this.metrikaGoal('send_form');
        this.fetchTele2Subscription({
          phone: '+' + this.phone.replace(/\D/g, '')
        }).then(res => {
          if (res.status && res.data.id) {
            this.subscriptId = res.data.id;
          } else {
            this.isSuccessSubscript = false;
          }
        })
      },
    },
  };

</script>
